.typography {

    p{
        font-size: 1.0rem;
        line-height: 2.0rem;
    }
    strong{
        font-weight:bold;
    }
    blockquote {
        border: #999 dashed 1px;
        padding: 30px;
        margin: 15px 0;
        border-radius: 20px;
    }
    figure{
        figcaption{
            @extend .text-gray-500;
            @extend .text-center;
            @extend .font-size-80;
            &.blocks-gallery-caption{
                padding-bottom: 20px;
            }
        }
        .wp-block-table table
        {
            @extend .table;
        }
        .wp-block-embed-youtube
        {
            .wp-block-embed__wrapper{
                @extend .ratio;
                @extend .ratio-16x9;
            }
        }
        .wp-block-image img
        {
            @extend .img-fluid;
            @extend .rounded;
            @extend .w-100;
            height: auto;
        }
    }

}
.page {

  h2{
    font-size: 1.3em;
    padding: 15px 0 15px 20px;
    border-left: $secondary 6px solid;
    border-bottom: $gray-500 1px dashed;
    margin: 25px 0;
    color: $gray-600;
  }
  h3{
    font-size: 1.1em;
    padding: 12px 0 12px 5px;
    border-bottom: $gray-500 1px dashed;
    margin: 20px 0;
    line-height: 2.0rem;
    color: $gray-600;
  }
  h4{
    font-size: 1.0em;
    padding: 12px 0 12px 5px;
    margin: 15px 0;
    line-height: 1.5rem;
  }

}
