//---------------------------------------------
//
//  Hamburger
//
//---------------------------------------------

$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 40px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : $light;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
 
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;
 
$hamburger-types: (
    spring
/*
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spring,
  spring-r,
  stand,
  stand-r,
  spin,
  spin-r,
  squeeze,
  vortex,
  vortex-r
*/
);

@import "~hamburgers/_sass/hamburgers/hamburgers";
