.text-gray-100{
  color: $gray-100;
}
.text-gray-200{
  color: $gray-200;
}
.text-gray-300{
  color: $gray-300;
}
.text-gray-400{
  color: $gray-400;
}
.text-gray-500{
  color: $gray-500;
}
.text-gray-600{
  color: $gray-600;
}
.bg-gray-100
{
  background-color: $gray-100;
}
.bg-gray-200
{
  background-color: $gray-200;
}
.bg-gray-300
{
  background-color: $gray-300;
}
.bg-gray-400
{
  background-color: $gray-400;
}
.bg-gray-500
{
  background-color: $gray-500;
}
.bg-gray-600
{
  background-color: $gray-600;
}
.mw-sm{
  max-width: map-get($grid-breakpoints, 'sm');
}
.mw-md{
  max-width: map-get($grid-breakpoints, 'md');
}
.mw-lg{
  max-width: map-get($grid-breakpoints, 'lg');
}
.mw-xl{
  max-width: map-get($grid-breakpoints, 'xl');
}
.font-size-90{
    font-size: 90% !important;
}
.font-size-80{
    font-size: 80% !important;
}
.font-size-80{
    font-size: 80% !important;
}
.font-size-70{
    font-size: 70% !important;
}
.font-size-60{
    font-size: 60% !important;
}
.font-size-50{
    font-size: 50% !important;
}
.font-size-40{
    font-size: 40% !important;
}
.font-size-30{
    font-size: 30% !important;
}
.display-1 {
  font-size: 3.0rem;
  line-height: 3.0rem;
  font-weight: 600;
}
.display-2 {
  font-size: 2.5rem;
  line-height: 3.0rem;
  font-weight: 600;
}
.display-3 {
  font-size: 2.0rem;
  line-height: 2.5rem;
  font-weight: 600;
}
.display-4 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 600;
}
.display-5 {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.d-middle{
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translateY(-50%) translateX(-50%); 
    -webkit-transform: translateY(-50%) translateX(-50%); 
}
