//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

@include googleFont((
  "name": "NotoSansJP",
  "folder": "NotoSansJP",
  "files": "NotoSansJP",
  "weights": (100, 300, 400, 700)
));