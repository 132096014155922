//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "hamburgers";
@import "global";
@import "typography";
@import "../../node_modules/aos/dist/aos.css";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    position: relative;
    // background-color: rgb(232, 227, 227);
    background-color: rgb(209, 199, 199);
    font-weight: 100;
    overflow-x: hidden;
    a{
        color: $light;
    }
}
#__D{fill:#D1C7C7;stroke:#FFFFFF;}
#__DOOR{fill:#D1C7C7;stroke:#FFFFFF;}
#__E{fill:#D1C7C7;stroke:#FFFFFF;}
#__P{fill:#D1C7C7;stroke:#FFFFFF;}
#__A{fill:#D1C7C7;stroke:#FFFFFF;}
#__R{fill:#D1C7C7;stroke:#FFFFFF;}
#__T{fill:#D1C7C7;stroke:#FFFFFF;}
#__S{fill:#D1C7C7;stroke:#FFFFFF;}
.departs_logo{
    transition: fill 4s ease;
}

#offcanvasMenu{
    // background-color: rgb(232, 227, 227);
    background-color: rgb(209, 199, 199);
}
#hamburger{
    position: fixed;
    z-index: 5000;
    right: 0px;
    left: auto;
    top: 0;
}
.hero {
 
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;   
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 100;
    }
    &.xs{
        height: 18vh;
    }
    &.sm{
        height: 20vh;
    }
    &.md{
        height: 40vh;
    }
}

.wp-block-table table{
    @extend .table;
    @extend .table-striped;
}

.container.index .row ul{
    @extend .list-group;
    @extend .list-group-flush;
    li{
        @extend .list-group-item;
        @extend .bg-transparent;
    }
}
div.wpforms-container-full .wpforms-form .wpforms-field-label{
    font-weight: 300 !important;
}
.page-numbers{
	background-color: transparent;
    @extend .page-link;
    color: $light;
}
.page-numbers.current {
	background-color: $light;
	color: $primary;
    font-weight: bolder;
}

.footer-content
{
    p{
        padding: 0;
        margin: 0;
    }
}
//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";